import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import uuid from "uuid/v4";
import Section from "../components/Section";
import chunk from "../utils/chunk";
import StackedPoint from "./StackedPoint";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center"
  },
  title: {
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(15)
    }
  },
  rowContainerSpacer: {
    marginBottom: theme.spacing(3)
  }
}));

const TriplePoint = ({ title, points = [] }) => {
  const classes = useStyles();
  const chunkedPoints = chunk(points, 3);

  return (
    <Section className={classes.container} gutters={[12, 0]}>
      <Container maxWidth="md">
        <Typography className={classes.title} variant="h4" component="h1">
          {title}
        </Typography>
      </Container>
      <Container>
        {chunkedPoints.map((pointsGroup, index) => (
          <Grid
            className={classnames({
              [classes.rowContainerSpacer]:
                points.length > 3 && index !== Math.ceil(points.length / 3 - 1)
            })}
            container
            key={uuid()}
          >
            {pointsGroup.map(point => (
              <Grid item xs={12} sm={4} key={point.title}>
                <StackedPoint
                  Icon={point.Icon}
                  title={point.title}
                  description={point.description}
                  link={point.link}
                  linkText={point.linkText}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Container>
    </Section>
  );
};

export default TriplePoint;
