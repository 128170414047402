import { Container, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { lightGreen } from "@material-ui/core/colors";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AirportShuttleOutlinedIcon from "@material-ui/icons/AirportShuttleOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import TimerIcon from "@material-ui/icons/Timer";
import WorkOutlinedIcon from "@material-ui/icons/WorkOutline";
import { makeStyles } from "@material-ui/styles";
import { graphql, Link as GatsbyLink } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import AvatarCard from "../components/AvatarCard";
import Button from "../components/Button";
import CheckList from "../components/CheckList";
import InlineNumberPoint from "../components/InlineNumberPoint";
import InlinePoint from "../components/InlinePoint";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";
import TriplePoint from "../components/TriplePoint";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    backgroundColor: theme.palette.common.light,
    [theme.breakpoints.up("lg")]: {
      backgroundImage: props => `url(${props.heroBackground.publicURL})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right"
    }
  },
  heroTitleSubtext: {
    margin: theme.spacing(1, 0, 3)
  },
  heroButtonGroup: {
    marginTop: theme.spacing(4)
  },
  servicesContainer: {
    backgroundColor: lightGreen[50]
  },
  servicesTitle: {
    marginBottom: theme.spacing(9)
  },
  servicesCategories: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(5)
    }
  },
  servicesCategoriesSpacer: {
    marginBottom: theme.spacing(4)
  },
  whyChooseUsContactColumn: {
    marginBottom: theme.spacing(8),
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      marginBottom: 0
    }
  },
  whyChooseUsContactColumnSticky: {
    position: "sticky",
    marginTop: theme.spacing(20),
    top: theme.spacing(20)
  },
  whyChooseUsContentColumn: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 0, 0, 8),
      borderLeft: `1px solid ${theme.palette.grey[200]}`
    }
  },
  whyChooseUsDivider: {
    margin: theme.spacing(15, 0),
    backgroundColor: theme.palette.grey[200]
  },
  whyChooseUsTitle: {
    marginBottom: theme.spacing(1)
  },
  whyChooseUsDescription: {
    marginBottom: theme.spacing(4)
  },
  whyChooseUdPartnerLogo: {
    margin: theme.spacing(3)
  },
  whyChooseUsAdvisersButton: {
    marginTop: theme.spacing(4)
  }
}));

const IndexPage = ({ data }) => {
  const classes = useStyles(data);

  return (
    <Layout>
      <SEO title="Home" />
      <Section className={classes.heroContainer} gutters={[7, 0]}>
        <Container>
          <Grid container>
            <Grid item xs={12} lg={10} xl={8}>
              <Typography variant="h1">
                Visionary solutions
                <Typography variant="inherit" color="primary">
                  .
                </Typography>
              </Typography>
              <Typography
                className={classes.heroTitleSubtext}
                variant="h5"
                component="h2"
              >
                Financing projects from £50,000 to £100 million
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={10} xl={8}>
              <CheckList
                points={[
                  "Specialising in sourcing funding for commercial property",
                  "Customer focused service driven by results",
                  "Expert advice from just £399"
                ]}
              />
            </Grid>
          </Grid>
          <Grid className={classes.heroButtonGroup} container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="default"
                giant
                component={GatsbyLink}
                to="/our-team/"
              >
                Our Team
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                giant
                component={GatsbyLink}
                to="/commercial-mortgage/"
              >
                Get Quote
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <TriplePoint
        title="Our team of expert advisers will help you find the best deal on your commercial finance"
        points={[
          {
            Icon: TimerIcon,
            title: "Move Fast",
            description: "Move fast with online case management and quoting.",
            link: "https://cosmicsoftware.co.uk/"
          },
          {
            Icon: SupervisedUserCircleOutlinedIcon,
            title: "Expert Advisers",
            description:
              "Our team of advisers are mostly ex-high street bankers with 15+ years experience.",
            link: "/our-team/"
          },
          {
            Icon: MessageOutlinedIcon,
            title: "Discuss Ideas",
            description:
              "Discuss your idea with us and we’ll tell you how to fund it."
          }
        ]}
      />
      <Section className={classes.servicesContainer}>
        <Container>
          <Typography
            className={classes.servicesTitle}
            variant="h3"
            component="h1"
            align="center"
          >
            Services
          </Typography>
          <Paper className={classes.servicesCategories} square elevation={0}>
            <Grid container>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={BusinessOutlinedIcon}
                  title="Commercial Mortgage"
                  description="Competitive commercial mortgages with rates generally starting from 2.25% over Bank of England base."
                  link="/commercial-mortgage/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={TimerIcon}
                  title="Bridging Loan"
                  description="1st or 2nd charge short term funding for a new purchase, capital raise against commercial, semi-commercial or residential property."
                  link="/bridging-loan/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={BuildOutlinedIcon}
                  title="Property Development"
                  description="Low cost development funding for experienced developers, and those starting the journey."
                  link="/property-development/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={HomeOutlinedIcon}
                  title="Buy-to-let"
                  description="Whether your an individual or limited company, we can help find funding for your next step."
                  link="/buy-to-let/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={WorkOutlinedIcon}
                  title="Business Loan"
                  description="Trading 2+ years? You could be eligible to draw up to £250k in under 24 hours. Find out how much you could borrow today!"
                  link="/business-loan/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={AirportShuttleOutlinedIcon}
                  title="Asset Finance"
                  description="Our experienced team will find lenders who can offer you flexible funding options and low rates."
                  link="/asset-finance/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid
                className={classes.servicesCategoriesSpacer}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <InlinePoint
                  Icon={CreditCardOutlinedIcon}
                  title="Invoice Finance"
                  description="Improve your cashflow by utilising a low rate, flexible invoice finance facility. We will help you find the best solution for your business."
                  link="/invoice-finance/"
                  linkText="Get Quote"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InlinePoint
                  Icon={AccountBalanceOutlinedIcon}
                  title="Bank Accounts"
                  description="Compare business accounts side-by-side in our easy to use tables. Find the best business bank account for you and apply online."
                  link="/business-banking/"
                  linkText="Compare Products"
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container>
            <Grid
              className={classes.whyChooseUsContactColumn}
              item
              xs={12}
              lg={5}
            >
              <div className={classes.whyChooseUsContactColumnSticky}>
                <Typography variant="h4" component="h1" align="center">
                  Why Choose Us?
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.whyChooseUsContentColumn}
              item
              xs={12}
              lg={7}
            >
              <Typography
                className={classes.whyChooseUsTitle}
                variant="h3"
                component="h2"
              >
                See the figures
              </Typography>
              <Typography
                className={classes.whyChooseUsDescription}
                color="textSecondary"
              >
                Let the data speak for itself.
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <InlineNumberPoint
                    Icon={GroupOutlinedIcon}
                    figure="50+"
                    title="Expert"
                    description="advisers ready to help find funding."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InlineNumberPoint
                    Icon={LocalOfferOutlinedIcon}
                    figure="£35b+"
                    title="In projects"
                    description="handled by our team."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InlineNumberPoint
                    Icon={EventAvailableIcon}
                    figure="10"
                    title="Years"
                    description="established commercial finance specialist."
                  />
                </Grid>
              </Grid>
              <Divider className={classes.whyChooseUsDivider} />
              <Typography
                className={classes.whyChooseUsTitle}
                variant="h3"
                component="h2"
              >
                Whole Market
              </Typography>
              <Typography
                className={classes.whyChooseUsDescription}
                color="textSecondary"
              >
                We have relationships with lenders across the market. From the
                high street to alternative lenders.
              </Typography>
              <Grid container alignItems="center">
                {data.partners.nodes.map(partner =>
                  partner.childImageSharp ? (
                    <Grid key={partner.id} item xs={6} sm={4}>
                      <Img
                        className={classes.whyChooseUdPartnerLogo}
                        fluid={partner.childImageSharp.fluid}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              <Divider className={classes.whyChooseUsDivider} />
              <Typography
                className={classes.whyChooseUsTitle}
                variant="h3"
                component="h2"
              >
                Expert Advisers
              </Typography>
              <Typography
                className={classes.whyChooseUsDescription}
                color="textSecondary"
              >
                Our advisers are the best there is. Extensive experience and
                advanced tooling means they're equipped to get you the best
                rate.
              </Typography>
              <Grid container spacing={6}>
                {data.advisers.nodes.map(adviser => (
                  <Grid key={adviser.id} item xs={12} sm={6}>
                    <AvatarCard
                      avatar={adviser.avatar}
                      role={adviser.role}
                      fullName={adviser.fullName}
                      bio={adviser.bio}
                    />
                  </Grid>
                ))}
              </Grid>
              <Button
                className={classes.whyChooseUsAdvisersButton}
                variant="contained"
                color="primary"
                giant
                component={GatsbyLink}
                to="/our-team/"
              >
                View all advisers
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroBackground: file(base: { eq: "home-hero-background.png" }) {
      publicURL
    }
    partners: allFile(filter: { dir: { regex: "/partners/" } }) {
      nodes {
        id
        childImageSharp {
          fluid(grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    advisers: allTeamMember(
      filter: { role: { eq: "Commercial Finance Broker" } }
      limit: 2
    ) {
      nodes {
        ...TeamMemberWithAvatar
      }
    }
  }
`;

export default IndexPage;
