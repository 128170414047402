import { Collapse, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Img from "gatsby-image";
import React, { useState } from "react";
import Button from "./Button";

const useStyles = makeStyles(theme => ({
  avatarCardRole: {
    margin: theme.spacing(2, 0, 1)
  },
  avatarCardName: {
    marginBottom: theme.spacing(2)
  },
  avatarCardBio: {}
}));

const AvatarCard = ({
  avatar,
  role,
  fullName = "",
  bio = "",
  maxBioLength = 250
}) => {
  const classes = useStyles();

  const [isShowingFullBio, setBioVisibility] = useState(
    bio.length < maxBioLength
  );

  return (
    <>
      <Img fluid={avatar.childImageSharp.fluid} />
      <Typography
        className={classes.avatarCardRole}
        variant="subtitle1"
        component="div"
      >
        {role}
      </Typography>
      <Typography
        className={classes.avatarCardName}
        variant="h5"
        component="div"
      >
        {fullName}
      </Typography>
      <Collapse in={isShowingFullBio} collapsedHeight="120px">
        <Typography
          className={classes.avatarCardBio}
          variant="body2"
          component="p"
        >
          {bio}
        </Typography>
      </Collapse>
      {!isShowingFullBio && (
        <Button
          variant="text"
          color="primary"
          onClick={() => setBioVisibility(true)}
        >
          Continue Reading
        </Button>
      )}
    </>
  );
};

export default AvatarCard;
