import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Link from "./Link";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: "4rem"
  },
  body: {
    padding: theme.spacing(0.5)
  },
  title: {
    marginBottom: theme.spacing(1.5)
  },
  description: {
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(1)
  }
}));

const InlinePoint = ({ Icon, title, description, link, linkText }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Icon className={classes.icon} />
      </Grid>
      <Grid className={classes.body} item xs={9}>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
        {link ? <Link href={link}>{linkText}</Link> : null}
      </Grid>
    </Grid>
  );
};

export default InlinePoint;
