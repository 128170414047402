const chunk = (array, size) => {
  const length = array === null ? 0 : array.length;

  if (!length || size < 1) {
    return [];
  }

  const result = new Array(Math.ceil(length / size));
  let index = 0;
  let resIndex = 0;

  while (index < length) {
    result[resIndex++] = array.slice(index, (index += size));
  }

  return result;
};

export default chunk;
